/***************************************************
  Imports
***************************************************/

import React from "react"
import { navigate } from "gatsby"

import Layout from "../../components/Layout"
import PageNumber from "../../components/PageNumber"

import D365Graphic from "../../images/D365-graphic.png"
import D365Illustration from "../../images/D365-illustration.svg"
import D365FeaturesAutomate from "../../images/D365-features-automate.png"
import D365FeaturesFinancials from "../../images/D365-features-financials.png"
import D365FeaturesSmarter from "../../images/D365-features-smarter.png"
import D365FeaturesTime from "../../images/D365-features-time.png"

/***************************************************
  Page Data
***************************************************/

const features = [
  {
    title: "Manage Your Financials",
    img: D365FeaturesFinancials,
    blocks: [
      {
        title: "Make Informed Decisions",
        info:
          "An all-in-one business management solution that helps your business connect financials, sales, service, and operations to streamline business processes, improve customer interactions and make better decisions.",
      },
      {
        title: "Accelerate Financial Close and Reporting",
        info:
          "An all-in-one business management solution that helps your business connect financials, sales, service, and operations to streamline business processes, improve customer interactions and make better decisions.",
      },
    ],
  },
  {
    title: "Improve Forecast Accuracy",
    blocks: [
      {
        info:
          "Refine financial forecasts by modeling and analyzing data across multiple dimensions. Customize reports using seamless Microsoft Excel integration.",
      },
    ],
  },
  {
    title: "Automate and Secure Your Supply Chain",
    img: D365FeaturesAutomate,
    blocks: [
      {
        title: "Optimize Inventory Levels",
        info:
          "Use built-in intelligence to predict when and what to replenish. Purchase only what you need with dynamically updated inventory levels.",
      },
      {
        title: "Maximize Profitability",
        info:
          "Use built-in intelligence to predict when and what to replenish. Purchase only what you need with dynamically updated inventory levels.",
      },
      {
        title: "Avoid Lost Sales and Reduce Shortages",
        info:
          "Maintain the right amount of inventory by automatically calculating stock levels, lead times, and reorder points. Suggest substitutes when requested items are out of stock.",
      },
    ],
  },
  {
    title: "Sell Smarter and Improve Customer Service",
    img: D365FeaturesSmarter,
    blocks: [
      {
        title: "Deliver Value at Every Touch Point",
        info:
          "Prioritize sales leads based on revenue potential. Keep track of all customer interactions and get guidance on best upsell, cross-sell, and renewal opportunities throughout your sales cycle.",
      },
      {
        title: "Boost Sales Productivity",
        info:
          "Accelerate the quote to cash process. Act quickly on sales-related inquiries, manage service requests, and process payments—all from within Outlook.",
      },
      {
        title: "Maximize Profitability",
        info:
          "Gain a comprehensive overview of your service tasks, workloads, and employee skills to effectively assign resources and accelerate case resolution.",
      },
    ],
  },
  {
    title: "Keep Projects on Time and Under Budget",
    img: D365FeaturesTime,
    blocks: [
      {
        title: "Stay on Budget",
        info:
          "Create, manage, and track customer projects using timesheets along with advanced job costing and reporting capabilities. Develop, modify, and control budgets to ensure project profitability.",
      },
      {
        title: "Plan with Precision",
        info:
          "Manage resource levels by planning capacity and sales. Track invoicing for customers against planned costs on orders and quotes.",
      },
      {
        title: "Analyze Project Performance",
        info:
          "Make effective decisions with real-time insight on project status, profitability, and resource-usage metrics.",
      },
    ],
  },
]

const modules = [
  "Financial Management",
  "Supply Chain Management",
  "Manufacturing",
  "Sales and Marketing",
  "Business Intelligence",
  "Human Resource Management",
  "Service Management",
  "Advanced Supply Chain Management",
  "RoleTailored",
  "Featured Benefits",
  "Project Management",
]

/***************************************************
  Component
***************************************************/

export default () => {
  const moduleUI = () =>
    modules.map((module, index) => {
      return (
        <div
          onClick={() =>
            navigate(
              `/microsoft-dynamics-365-business-central/${module
                .toLowerCase()
                .replaceAll(" ", "-")}`
            )
          }
        >
          <h3>{module}</h3>
          <div>
            <span>{(index + 1).toString().padStart(2, "0")}</span>
          </div>
        </div>
      )
    })

  const featureUI = () =>
    features.map(feature => {
      const { title, img } = feature
      return (
        <>
          <div className="col-4 span-4 offset">
            <div>
              <h2>{title}</h2>
              {img && <img src={img} />}
            </div>
          </div>
          <div className="col-9 span-4">
            {feature.blocks.map(block => {
              const { title, info } = block
              return (
                <div>
                  {title && <h3 className="small">{title}</h3>}
                  <p>{info}</p>
                </div>
              )
            })}
          </div>
        </>
      )
    })

  return (
    <Layout
      headerTheme="light"
      className="microsoft-dynamics-365-business-central background-offwhite"
      title="Microsoft Dynamics 365 Business Central"
      description="An all-in-one business management solution that helps your business connect financials, sales, service, and operations to make better decisions."
    >
      <section className="section-hero">
        <div className="col-3 span-5">
          <h1>Microsoft Dynamics 365 Business Central</h1>
          <h2>
            Connect Your Business - Make Better Decisions - Start and Grow
            Easily
          </h2>
          <p>
            An all-in-one business management solution that helps your business
            connect financials, sales, service, and operations to streamline
            business processes, improve customer interactions and make better
            decisions.
          </p>
        </div>
        <img
          className="col-8 span-6"
          src={D365Graphic}
          alt="Microsoft Dynamics 365 - Business Central"
        />
      </section>

      <section className="section-introduction">
        <PageNumber
          className="col-3 span-11 dark"
          number="02"
          text="OUR MODULES"
        />
        <div className="col-4 span-9 modules">{moduleUI()}</div>
        <img className="col-9 span-5 illustration" src={D365Illustration} />
      </section>

      <section className="section-features">
        <PageNumber
          className="col-3 span-11 dark"
          number="02"
          text="OUR FEATURES"
        />

        {featureUI()}
      </section>

      <div className="features-endcap"></div>
    </Layout>
  )
}
